<template>
  <div class="left">
    <div class="area">
      <div class="area_title" style="margin-top: 0;">
        综合展示
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content" style="padding: 20px 35px;">
        <div class="overview" style="margin-top: 0px;">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon1"></div>
          </div>
          <div class="overview_right">
            <div class="text">1020</div>
            <div class="text_tip">学生人数/人</div>
          </div>
        </div>
        <div class="overview">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon2"></div>
          </div>
          <div class="overview_right">
            <div class="text">121</div>
            <div class="text_tip">教职工人数/人</div>
          </div>
        </div>
        <div class="overview" style="margin-bottom: 0;">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon3"></div>
          </div>
          <div class="overview_right">
            <div class="text">1</div>
            <div class="text_tip">学院个数/个</div>
          </div>
        </div>
        <div class="overview" style="margin-bottom: 0;">
          <div class="overview_left">
            <div class="circle"></div>
            <div class="overview_icon icon4"></div>
          </div>
          <div class="overview_right">
            <div class="text">1</div>
            <div class="text_tip">实验室/个</div>
          </div>
        </div>
      </div>
    </div>
    <ChoseBuilding :manageInfo="manageInfo" @getManageInfo="getManageInfo"></ChoseBuilding>
  </div>
  <div class="right">
    <div class="area">
      <div class="area_title">
        教室管理
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content">
        <div class="overview_" style="margin-top: 0px;">
          <div class="overview_left_">
            <div class="overview_icon_ icon5"></div>
            <div class="text_tip_">教室总数</div>
          </div>
          <div class="overview_line"></div>
          <div class="overview_right">
            <div class="text">1020 <span>间</span></div>
          </div>
        </div>
        <div class="overview_">
          <div class="overview_left_">
            <div class="overview_icon_ icon5"></div>
            <div class="text_tip_">教室使用数量</div>
          </div>
          <div class="overview_line"></div>
          <div class="overview_right">
            <div class="text">121 <span>间</span></div>
          </div>
        </div>
        <div class="overview_">
          <div class="overview_left_">
            <div class="overview_icon_ icon5"></div>
            <div class="text_tip_">教室空闲数量</div>
          </div>
          <div class="overview_line"></div>
          <div class="overview_right">
            <div class="text">22 <span>间</span></div>
          </div>
        </div>
        <div class="overview_">
          <div class="overview_left_">
            <div class="overview_icon_ icon5"></div>
            <div class="text_tip_">教室使用率</div>
          </div>
          <div class="overview_line"></div>
          <div class="overview_right">
            <div class="text">80 <span>%</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="area">
      <div class="area_title">
        教学管理
        <div class="area_title_icon"></div>
        <div class="area_title_line">
          <div class="area_title_line_ract"></div>
        </div>
      </div>
      <div class="area_content">
        <div class="pie_con">
          <div id="pie1" style="width: 100%;height: 200px;"></div>
          <div class="pie_num">98%</div>
          <div class="pie_label">学生出勤率</div>
          <div class="label_container">
            <div class="lebal">
              <div class="label_icon" style="background-color: #30D6C2;"></div>
              <div class="label_text" style="color: #30D6C2;">学生实到人数</div>
              <div class="label_text" style="color: #30D6C2;">980人</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #4F4F4F;"></div>
              <div class="label_text">学生未到人数</div>
              <div class="label_text">45人</div>
              <div class="label_line"></div>
            </div>
          </div>
        </div>
        <div class="pie_con">
          <div id="pie2" style="width: 100%;height: 200px;"></div>
          <div class="pie_num" style="color: #BF79AF;">98%</div>
          <div class="pie_label">教师出勤率</div>
          <div class="label_container">
            <div class="lebal">
              <div class="label_icon" style="background-color: #BF79AF;"></div>
              <div class="label_text" style="color: #BF79AF;">教师实到人数</div>
              <div class="label_text" style="color: #BF79AF;">112人</div>
              <div class="label_line"></div>
            </div>
            <div class="lebal">
              <div class="label_icon" style="background-color: #4F4F4F;"></div>
              <div class="label_text">教师未到人数</div>
              <div class="label_text">3人</div>
              <div class="label_line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="manage_pop" v-show="manageInfo.isManage">
    <div class="manage_free_pop" id="manage_free_pop" v-show="!manageInfo.isRoomUse">
      <div class="close-icon" @click="manageInfo.isManage=false"></div>
      <div class="header">
        <p class="room_num">{{ manageInfo.deviceName }}</p>
        <p class="room_state">空闲中</p>
      </div>
    </div>
    <div class="manage_use_pop" id="manage_use_pop" v-show="manageInfo.isRoomUse">
      <div class="manage_use_table">
        <div class="header">
          <p class="room_num">{{ manageInfo.deviceName }}</p>
          <p class="room_state">使用中</p>
          <div class="close-icon" @click="manageInfo.isManage=false;manageInfo.isViewList=false"></div>
        </div>
        <div class="content">
          <div class="content_">
            <div class="content_text" style="padding-left: 30px">
              <p class="text">课程名：</p>
              <p class="text_">中医药</p>
            </div>
            <div class="content_text">
              <p class="text">老师名：</p>
              <p class="text_">黄老师</p>
            </div>
          </div>
          <div class="content_">
            <div class="content_text" style="padding-left: 30px;width: 100%">
              <p class="text">占用时间段：</p>
              <p class="text_">2023年2月27日 8:00-8:40</p>
            </div>
          </div>
          <div class="content_">
            <div class="content_text" style="padding-left: 30px">
              <p class="text">学院名：</p>
              <p class="text_">针灸推拿系</p>
            </div>
            <div class="content_text">
              <p class="text">专业：</p>
              <p class="text_">针灸推拿</p>
            </div>
          </div>
          <div class="content_">
            <div class="content_text" style="padding-left: 30px">
              <p class="text">年级：</p>
              <p class="text_">2023级</p>
            </div>
            <div class="content_text">
              <p class="text">出勤率：</p>
              <p class="text_">90%</p>
            </div>
          </div>
          <div class="content_">
            <div class="content_text" style="padding-left: 30px">
              <p class="text">课程安排：</p>
              <p class="text_" style="cursor: pointer;color: #30D6C2" @click="manageInfo.isViewList=true">查看</p>
            </div>
          </div>
        </div>
      </div>
      <div class="student_list" v-show="manageInfo.isViewList">
        <div class="close-icon" @click="manageInfo.isViewList=false"></div>
        <div class="header">
          <p class="text">课程安排</p>
        </div>
        <div class="list">
          <div class="list_item" v-for="item in manageInfo.courseSchedule" :key="item.value">
            <p class="text">{{ item.label }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import Global from "../js/global";
import ChoseBuilding from "./ChoseBuilding";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import Message from "../js/Message";

export default {
  name: "TeachingManagement",
  components: {
    ChoseBuilding
  },
  data() {
    return {
      focus: false,
      name: "",
      manageInfo: {
        deviceName: '',
        isManage: false,
        isRoomUse: true,
        isViewList: false,
        courseSchedule: [
          {value: 1, label: "8:00-8:40 中医药"},
          {value: 2, label: "9:00-9:40 针灸"},
          {value: 3, label: "10:00-10:40 推拿"},
        ],
        chosePoint: null
      }
    };
  },
  mounted() {
    this.initPie1();
    this.initPie2();
    // this.floorList
    this.floorList = Global.floorList;
  },
  unmounted() {
    if (window.myChart_1) {
      window.myChart_1.dispose();
      window.myChart_1 = null;
    }
    if (window.myChart_2) {
      window.myChart_2.dispose();
      window.myChart_2 = null;
    }
  },
  watch: {
    "manageInfo.isManage": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          Message.setPopPosition("manage_pop", this.manageInfo.chosePoint)
          let vector2 = Global.vector3Tovector2(this.manageInfo.chosePoint);
          new Global.DragAndZoom("manage_pop", vector2)
        } else {
          scene.onBeforeRenderObservable.remove(Message.observer)
        }
      }
    }
  },
  methods: {
    choseLabel(data) {
      this.name = data.name;
      this.focus = false;
    },
    initPie2() {
      let container = document.getElementById("pie2");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        series: [
          {
            type: "pie",
            radius: ["60%", "70%"],
            center: ["27%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 10,
              itemStyle: {
                color: "transparent"
              }
            },
              {
                value: 90,
                name: "rose2",
                itemStyle: {
                  color: "#BF79AF"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["60%", "60%"],
            center: ["27%", "50%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 10,
              itemStyle: {
                color: "#4F4F4F"
              }
            },
              {
                value: 90,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_1 = myChart_;
    },
    initPie1() {
      let container = document.getElementById("pie1");
      let myChart_ = echarts.init(container);
      // 绘制图表
      let option = {
        series: [
          {
            type: "pie",
            radius: ["60%", "70%"],
            center: ["27%", "50%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 10,
              itemStyle: {
                color: "transparent"
              }
            },
              {
                value: 90,
                name: "rose2",
                itemStyle: {
                  color: "#30D6C2"
                }
              }
            ]
          },
          {
            type: "pie",
            radius: ["60%", "60%"],
            center: ["27%", "50%"],
            label: {
              show: false,
              position: "center"
            },
            labelLine: {
              show: false
            },
            data: [{
              value: 10,
              itemStyle: {
                color: "#4F4F4F"
              }
            },
              {
                value: 90,
                itemStyle: {
                  color: "transparent"
                }
              }
            ]
          }
        ]
      };
      myChart_.setOption(option);
      window.myChart_2 = myChart_;
    },
    getManageInfo(info) {
      this.manageInfo = info
    },
  }
};
</script>

<style lang="less" scoped>
.manage_pop {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}
.manage_free_pop {
  position: absolute;
  left: 0;
  top: 0;
  width: 358.78px / 1.5;
  height: 57.71px / 1.5;
  background-image: url("../assets/image/bg/room_free.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .close-icon {
    position: absolute;
    top: 5px;
    right: 10px;
    width: 25px;
    height: 25px;
    background-image: url("../assets/image/bg/building_close.png");
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    cursor: pointer;
  }

  .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .room_num {
      position: absolute;
      left: 30px;
      height: 16px;
      //margin-left: 30px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #7D5E0E
    }

    .room_state {
      position: absolute;
      left: 100px;
      height: 16px;
      //margin-left: 65px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #20F44E
    }
  }
}

.manage_use_pop {
  position: absolute;
  left: 0;
  top: 0;
  width: 470.78px / 1.3 + 268px / 1.3;
  height: 291.71px / 1.3;
  display: flex;
  align-items: flex-end;

  .header {
    width: 100%;
    height: 57.71px / 1.3;
    display: flex;
    align-items: center;

    .room_num {
      margin-left: 30px;
      height: 16px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #7D5E0E;
      width: 50px;
    }

    .room_state {
      margin-left: 20px;
      height: 16px;
      //margin-left: 90px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #E93232;
      width: 50px;
    }

    .close-icon {
      margin-left: 170px;
      width: 25px;
      height: 25px;
      background-image: url("../assets/image/bg/building_close.png");
      -webkit-background-size: 100% 100%;
      background-size: 100% 100%;
      cursor: pointer;
    }
  }

  .content {
    width: 100%;
    height: 160px;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
  }
}

.manage_use_table {
  width: 470.78px / 1.3;
  height: 291.71px / 1.3;
  background-image: url("../assets/image/bg/room_use.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.student_list {
  width: 268px / 1.3;
  height: 287px / 1.3;
  background-image: url("../assets/image/bg/list.png");
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;

  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    background-image: url("../assets/image/bg/building_close.png");
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    cursor: pointer;
    //z-index: 999;
  }

  .header {
    width: 100%;
    height: 30px;

    .text {
      width: 100px;
      height: 16px;
      margin-left: 10px;
      margin-top: 10px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #FFF7AB
    }
  }

  .list {
    width: 100%;
    height: 287px / 1.3 - 30px;
    padding-top: 10px;
    overflow: scroll;

    .list_item {
      width: 100%;
      height: 30px;
    }

    .text {
      //width: 100px;
      height: 16px;
      margin-left: 10px;
      font-size: 16px;
      font-family: 'FZZhengCu';
      color: #FAFAFA
    }
  }
}

.manage_use_pop .content_ {
  width: 100%;
  height: (291.71px - 57.71px) / 1.3 / 4;
  display: flex;

  .content_text {
    width: 50%;
    height: 100%;
    display: flex;

    .text {
      font-size: 15px;
      font-family: 'FZZhengCu';
      color: #FAFAFA;
    }

    .text_ {
      font-size: 15px;
      font-family: 'FZZhengCu';
      color: #FAFAFA;
    }
  }
}

.area {
  margin-top: 27px;
}

.pie_num {
  width: 80px;
  height: 40px;
  color: #30D6C2;
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  position: absolute;
  left: 53px;
  top: 80px;
}

.pie_label {
  width: 100px;
  height: 25px;
  position: absolute;
  left: 43px;
  bottom: 3px;
  color: #fff;
  font-size: 16px;
  text-align: center;
  line-height: 25px;
}

.label_line {
  width: 100%;
  bottom: 0;
  height: 1px;
  position: absolute;
  left: 0;
  background-image: linear-gradient(to right, rgba(144, 249, 252, 1) 0%, rgba(125, 125, 125, 0.4) 100%);
}

.label_text {
  margin-top: 4px;
  font-size: 14px;
  color: #ffffff;
}

.label_icon {
  width: 14px;
  height: 14px;
  margin-left: 6.5px;
  background-color: #fff;
}

.lebal {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  height: 47px;
}

.label_container {
  position: absolute;
  width: 160px;
  right: 10px;
  top: 42px;
}

.pie_con {
  width: 100%;
  position: relative;
}

.whirling {
  transform: rotateZ(-180deg);
}

.select_:first-child {
  margin-top: 10px;
}

.select_:last-child {
  margin-bottom: 10px;
}

.select_ {
  padding: 15px 30px;
  color: #1EBCBF;
  cursor: pointer;
}

.select_con {
  position: absolute;
  width: 130%;
  height: 200px;
  left: 0;
  top: 50px;
  z-index: 2;
  background-color: #fff;
  border-radius: 5px;
  overflow: scroll;
  transition: all 0.3s;
}

.input_arrow {
  width: 21px;
  transition: all 0.3s;
  height: 16.5px;
  background-image: url(../assets/image/icon/arrow.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  position: absolute;
  right: 10px;
  top: 15px;
}

.b_input ._input {
  width: 100%;
  height: 100%;
  border: 1px solid #30D6C2;
  border-radius: 5px;
  padding: 0 15px;
  background-color: transparent;
  color: #ffffff;
  cursor: pointer;
  line-height: 46px;
  font-size: 18px;
}

.b_input {
  position: relative;
  width: 150px;
  height: 46px;
  margin-left: 20px;
}

.icon1 {
  background-image: url(../assets/image/icon/xuesheng.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon2 {
  background-image: url(../assets/image/icon/laoshi.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon3 {
  background-image: url(../assets/image/icon/xuexiao.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon4 {
  background-image: url(../assets/image/icon/shiyan.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.icon5 {
  background-image: url(../assets/image/icon/fanjian.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.overview_left {
  margin-right: 14px;
}

.overview_left_ {
  display: flex;
  align-items: center;
}

.text {
  font-size: 18px;
  color: #30D6C2;
}

.left .overview_right .text {
  font-size: 24px;
  color: #30D6C2;
}

.text span {
  font-size: 14px;
  color: #30D6C2;
}

.overview_line {
  width: 60px;
  height: 1px;
  background-color: #bdbdbd;
}

.left .text_tip {
  margin-top: 5px;
  font-size: 16px;
  color: #F2F2F2;
}

.text_tip {
  font-size: 14px;
  color: #F2F2F2;
}

.text_tip_ {
  width: 120px;
  margin-top: 4px;
  font-size: 18px;
  color: #F2F2F2;
}

.overview_icon {
  width: 20px;
  height: 20px;
}

.overview_icon_ {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.circle {
  width: 19px;
  height: 19px;
  margin-bottom: 10px;
  background-color: rgba(48, 204, 214, 0.3);
  border-radius: 50%;
}

.area_content {
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0px;
}

.area_content_ {
  padding: 20px 10px;
  padding-bottom: 0px;
}

.overview {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  width: 138px;
}

.overview_ {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
  border: 1px solid #45AFA3;
  background-color: rgba(18, 62, 56, 0.40);
  padding: 10px 15px;
  border-radius: 5px;
}
</style>
